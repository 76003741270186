import React from 'react';
import { Box, Grid, Typography, List, Divider } from '@mui/material';
import { styled } from '@mui/system';
import MKBox from 'components/MKBox';
import TNBImage from 'assets/images/partners/tnbes.jpg';
import TNB from 'assets/images/partners/tnb.jpeg';
import isnet from 'assets/images/partners/spka.png';
import Mimos from 'assets/images/partners/mimos.jpg';
import DNZ from 'assets/images/partners/dnz.png';
import Axicom from 'assets/images/partners/axicom.jpg';

// Styled Components for custom styling
const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  padding: '50px 20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 0px',
  },
}));

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '300px',
  maxHeight: '200px',
  borderRadius: '1px',
  margin: '0 auto', // Center the image
  display: 'block', // Ensures the image behaves as a block element
  [theme.breakpoints.down('sm')]: {
    maxWidth: '250px',
  },
}));

const BenefitsList = styled(List)({
  listStyleType: 'none',
  paddingLeft: '0',
});

const PartnerContent = () => {
  return (
    <MKBox component="section" py={12} mt={-5}>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Image src={Mimos} alt="Mimos" />
            <Divider
              style={{
                margin: '20px auto',
                width: '50%',
                height: '3px',
                backgroundColor: '#10b6ca',
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 4, md: 0 }}>
            <BenefitsList>
              <Typography variant="h3"
                textAlign={{ xs: 'center', md: 'center' }}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: '#59535c',
                  fontFamily: "'Caudex', serif",
                  fontWeight: 'bold',
                  [breakpoints.down('md')]: {
                    fontSize: size['3xl'],
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: size['2xl'],
                  },
                })}
              >
                Mimos, Kuala Lumpur, Malaysia
              </Typography>
              <Typography
                variant="body2"
                color="#59535c"
                mb={0}
                sx={({ breakpoints }) => ({
                  fontSize: '1.2rem',
                  lineHeight: '1.5',
                  textAlign: { xs: 'center', md: 'center' },
                  mt: 0,
                  [breakpoints.down('md')]: {
                    fontSize: '1rem',
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                  },
                })}
              >
                Our Technology Partner since 2020.
                We collaborate with Mimos and license the Technologies to implement in Commercial and Industrial Building&apos;s. Research and development centre specialist in semi conductor, advanced technology and ICT.
                <br /><a
                  href="https://www.mimos.my/main/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit', fontStyle: 'italic' }}
                >
                  mimos.my
                </a>
              </Typography>
            </BenefitsList>
          </Grid>
          <Grid item xs={12} md={1}>
          </Grid>
        </Grid>
      </Container>
      <Container mt={4}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Image src={TNBImage} alt="TNB Energy Service" />
            <Divider
              style={{
                margin: '20px auto',
                width: '50%',
                height: '3px',
                backgroundColor: '#10b6ca',
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 4, md: 0 }}>
            <BenefitsList>
              <Typography variant="h3"
                textAlign={{ xs: 'center', md: 'center' }}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: '#59535c',
                  fontFamily: "'Caudex', serif",
                  fontWeight: 'bold',
                  [breakpoints.down('md')]: {
                    fontSize: size['3xl'],
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: size['2xl'],
                  },
                })}
                >
                TNB Energy Services
              </Typography>
              <Typography
               variant="body2"
               color="#59535c"
               mb={0}
               sx={({ breakpoints }) => ({
                 fontSize: '1.2rem',
                 lineHeight: '1.5',
                 textAlign: { xs: 'center', md: 'center' },
                 mt: 0,
                 [breakpoints.down('md')]: {
                   fontSize: '1rem',
                 },
                 [breakpoints.down('sm')]: {
                   fontSize: '0.875rem',
                 },
               })}
              >
                Offering innovative energy solutions such as energy efficiency consulting, renewable energy, and energy management systems. They focus on helping clients reduce energy consumption and costs while minimizing environmental impact, supporting Malaysia&apos;s transition to sustainable energy practices.
                <br /><a
                  href="https://www.tnbes.com.my"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit', fontStyle: 'italic' }}
                >
                  www.tnbes.com.my
                </a>
              </Typography>
            </BenefitsList>
          </Grid>
          <Grid item xs={12} md={1}>
          </Grid>
        </Grid>
      </Container>
      <Container mt={4}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Image src={TNB} alt="TNB " />
            <Divider
               style={{
                margin: '20px auto',
                width: '50%',
                height: '3px',
                backgroundColor: '#10b6ca',
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 4, md: 0 }}>
            <BenefitsList>
              <Typography variant="h3"
                textAlign={{ xs: 'center', md: 'center' }}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: '#59535c',
                  fontFamily: "'Caudex', serif",
                  fontWeight: 'bold',
                  [breakpoints.down('md')]: {
                    fontSize: size['3xl'],
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: size['2xl'],
                  },
                })}>
                Tenaga Nasional Berhad
              </Typography>
              <Typography
               variant="body2"
               color="#59535c"
               mb={0}
               sx={({ breakpoints }) => ({
                 fontSize: '1.2rem',
                 lineHeight: '1.5',
                 textAlign: { xs: 'center', md: 'center' },
                 mt: 0,
                 [breakpoints.down('md')]: {
                   fontSize: '1rem',
                 },
                 [breakpoints.down('sm')]: {
                   fontSize: '0.875rem',
                 },
               })}
              > Malaysia&apos;s largest electricity utility company, providing power generation, transmission, and distribution services nationwide. TNB is dedicated to sustainable energy solutions, focusing on renewable energy and energy efficiency to drive economic growth and environmental goals.
                <br /><a
                  href="https://www.tnb.com.my/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit', fontStyle: 'italic' }}
                >
                  www.tnb.com.my
                </a>
              </Typography>
            </BenefitsList>
          </Grid>
          <Grid item xs={12} md={1}>
          </Grid>
        </Grid>
      </Container>
      <Container mt={4}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Image src={DNZ} alt="DNZ.Asia" />
            <Divider
             style={{
              margin: '0px auto',
              width: '50%',
              height: '3px',
              backgroundColor: '#10b6ca',
            }}
            />
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 4, md: 0 }}>
            <BenefitsList>
              <Typography variant="h3"
                textAlign={{ xs: 'center', md: 'center' }}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: '#59535c',
                  fontFamily: "'Caudex', serif",
                  fontWeight: 'bold',
                  [breakpoints.down('md')]: {
                    fontSize: size['3xl'],
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: size['2xl'],
                  },
                })}>
                DNZ.ASIA, Singapore
              </Typography>
              <Typography
                variant="body2"
                color="#59535c"
                mb={0}
                sx={({ breakpoints }) => ({
                  fontSize: '1.2rem',
                  lineHeight: '1.5',
                  textAlign: { xs: 'center', md: 'center' },
                  mt: 0,
                  [breakpoints.down('md')]: {
                    fontSize: '1rem',
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                  },
                })}
              >
                IT solutions provider specializing in data center services, cloud solutions, and IT infrastructure. With a focus on innovation and reliability, DNZ Asia delivers customized solutions that enhance business performance and support digital transformation across various industries.
                <br /><a
                  href="https://www.dnz.asia/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit', fontStyle: 'italic' }}
                >
                  dnz.asia
                </a>
              </Typography>
            </BenefitsList>
          </Grid>
          <Grid item xs={12} md={1}>
          </Grid>
        </Grid>
      </Container>
      <Container mt={4}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Image src={isnet} alt="iScada Net" />
            <Divider
              style={{
                margin: '0px auto',
                width: '50%',
                height: '3px',
                backgroundColor: '#10b6ca',
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} mt={{ xs: 4, md: 0 }}>
            <BenefitsList>
              <Typography variant="h3"
                textAlign={{ xs: 'center', md: 'center' }}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: '#59535c',
                  fontFamily: "'Caudex', serif",
                  fontWeight: 'bold',
                  [breakpoints.down('md')]: {
                    fontSize: size['3xl'],
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: size['2xl'],
                  },
                })}>
                iScada Net, Selangor, Malaysia
              </Typography>
              <Typography
               variant="body2"
               color="#59535c"
               mb={0}
               sx={({ breakpoints }) => ({
                 fontSize: '1.2rem',
                 lineHeight: '1.5',
                 textAlign: { xs: 'center', md: 'center' },
                 mt: 0,
                 [breakpoints.down('md')]: {
                   fontSize: '1rem',
                 },
                 [breakpoints.down('sm')]: {
                   fontSize: '0.875rem',
                 },
               })}
              >
                Specializes in industrial automation and monitoring solutions, offering advanced SCADA (Supervisory Control and Data Acquisition) systems. They provide real-time data analytics and remote monitoring for various industries, enhancing operational efficiency, productivity, and decision-making through innovative technology solutions.
                <br /><a
                  href="https://isnet.my/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit', fontStyle: 'italic' }}
                >
                  isnet.my
                </a>
              </Typography>
            </BenefitsList>
          </Grid>
          <Grid item xs={12} md={1}>
          </Grid>
        </Grid>
      </Container>
      <Container mt={4}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Image src={Axicom} alt="Axicom" />
          </Grid>
          <Grid item xs={12} md={5} mt={{xs:4 , md:0}}>
            <BenefitsList>
              <Typography variant="h3"
                textAlign={{ xs: 'center', md: 'center' }}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: '#59535c',
                  fontFamily: "'Caudex', serif",
                  fontWeight: 'bold',
                  [breakpoints.down('md')]: {
                    fontSize: size['3xl'],
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: size['2xl'],
                  },
                })}>
                AxiCom, Selangor, Malaysia
              </Typography>
              <Typography
                variant="body2"
                color="#59535c"
                mb={0}
                sx={({ breakpoints }) => ({
                  fontSize: '1.2rem',
                  lineHeight: '1.5',
                  textAlign: { xs: 'center', md: 'center' },
                  mt: 0,
                  [breakpoints.down('md')]: {
                    fontSize: '1rem',
                  },
                  [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                  },
                })}
              >
                Specializing in building automation and energy management solutions. They offer a range of services, including system integration, maintenance, and consultancy, to enhance building efficiency and sustainability, leveraging cutting-edge technology for smarter, greener operations.
                <br /> <a
                  href="https://www.axicom.my/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit', fontStyle: 'italic' }}
                >
                  axicom.my
                </a>
              </Typography>
            </BenefitsList>
          </Grid>
          <Grid item xs={12} md={1}>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default PartnerContent;
