// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navigation/Navbar"
import DefaultFooter from "examples/Footers/Footer/footer"

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import Counters from "pages/Presentation/sections/Counters";

// Images
import TopPic from "assets/images/partners/iotBackground.jpg";

function IotPage() {
    return (
        <>
            <DefaultNavbar routes={routes} transparent light />
            <MKBox position="relative" width="100%" height="55vh" display="flex" alignItems="center" justifyContent="center">
                <img
                    src={TopPic}
                    alt="Top Image"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <MKTypography
                    variant="h1"
                    component="div"
                    color="white"
                    sx={{
                        position: "absolute",
                        fontSize: { xs: "2.5rem", sm: "3rem", md: "4rem", lg: "5rem" },
                        fontWeight: "bold",
                        mt: { xs: 4, md: 8 },
                        textAlign: "center",
                        textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
                        px: { xs: 2, md: 0 }, // Padding on smaller screens
                    }}
                >
                    AI-Driven IOT Ecosystems
                </MKTypography>
            </MKBox>
            <Card
                sx={{
                    p: 0,
                    mx: { xs: 0, lg: 0 },
                    mt: 0,
                    mb: 0,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                    borderRadius: 0,
                }}
            >
                <MKBox
                    component="section"
                    sx={{
                        minHeight: "75vh",
                        width: "100%",
                        backgroundColor: "light green", // Set to your desired dark color
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "grid",
                        placeItems: "center",
                        py: 4, // Add padding to the top and bottom
                    }}
                >
                    <Container>
                        <Grid container alignItems="center">
                            <Grid item xs={12} mt={2} md={12} sx={{ ml: "auto" }}>
                                <MKBox mb={5}>
                                    <MKTypography
                                        variant="h3"
                                        textAlign="center"
                                        mt={0}
                                        mb={1}
                                        sx={({ breakpoints, typography: { size } }) => ({
                                            color: "#59535c",
                                            fontSize: size["4xl"], // Default for large screens
                                            [breakpoints.down("lg")]: {
                                                fontSize: size["3xl"], // Adjust for large tablets/smaller desktops
                                            },
                                            [breakpoints.down("md")]: {
                                                fontSize: size["2xl"], // Adjust for medium screens (tablets)
                                            },
                                            [breakpoints.down("sm")]: {
                                                fontSize: size["xl"], // Adjust for small screens (phones)
                                            },
                                            [breakpoints.down("xs")]: {
                                                fontSize: size["lg"], // Adjust for extra small screens
                                            },
                                        })}
                                    >
                                        Optimizing Chiller Plant Operations with Real-Time Data and AI
                                    </MKTypography>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={5} mt={4}>
                                <MKBox mb={5}>
                                    <MKTypography
                                        color="Black"
                                        sx={{
                                            fontSize: { xs: "1.5rem", md: "1.2rem" },
                                            lineHeight: "1.2",
                                            textAlign: "center",
                                            fontWeight: 700,
                                            mt: 0.5,
                                        }}
                                    >
                                        Real-Time Environmental Monitoring
                                    </MKTypography>
                                    <Divider
                                        style={{
                                            margin: '10px auto',
                                            width: '30%',
                                            height: '3px',
                                            backgroundColor: '#43abc1',
                                        }}
                                    />
                                    <MKBox sx={{
                                        px: { xs: 2, md: 1 },
                                        pt: 2,
                                        pb: 1,
                                        backgroundColor: 'rgba(217, 232, 238, 0.8)',
                                        height: { xs: 'auto', md: '180px' },
                                    }}>
                                        <MKTypography
                                            variant="body2"
                                            color="text"
                                            sx={{
                                                fontSize: "1rem",
                                                lineHeight: "1.5",
                                                textAlign: "center",
                                                mt: { xs: 0, md: 3 },
                                                ml: 'auto',
                                                mr: 'auto',
                                                maxWidth: '100%',
                                                width: 'auto',
                                            }}
                                        >
                                            Our system begins by capturing the real-time temperature and humidity levels within the building using RHT sensors. This data is essential for understanding the building’s environmental conditions
                                        </MKTypography>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={5} mt={-1} sx={{ ml: "auto" }}>
                                <MKBox position="relative">
                                    <MKTypography
                                       color="Black"
                                       sx={{
                                           fontSize: { xs: "1.5rem", md: "1.2rem" },
                                           lineHeight: "1.2",
                                           textAlign: "center",
                                           fontWeight: 700,
                                           mt: 0.5,
                                       }}
                                    >
                                        Comprehensive Data Collection
                                    </MKTypography>
                                    <Divider
                                        style={{
                                            margin: '10px auto',
                                            width: '30%',
                                            height: '3px',
                                            backgroundColor: '#43abc1',
                                        }}
                                    />
                                    <MKBox sx={{
                                        px: { xs: 2, md: 1 },
                                        pt: 2,
                                        pb: 1,
                                        backgroundColor: 'rgba(217, 232, 238, 0.8)',
                                        height: { xs: 'auto', md: '180px' },
                                    }}
                                    >
                                        <MKTypography
                                            variant="body2"
                                            color="text"
                                            sx={{
                                                fontSize: "1rem",
                                                lineHeight: "1.5",
                                                textAlign: "center",
                                                mt: { xs: 0, md: 3 },
                                                ml: 'auto',
                                                mr: 'auto',
                                                maxWidth: '100%',
                                                width: 'auto',
                                            }}
                                        >
                                            WISP collects vital chiller plant data like energy consumption, condenser and chilled water temperatures, and flow rates, ensuring comprehensive monitoring of the chiller’s overall performance.
                                        </MKTypography>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={5} mt={4}>
                                <MKBox mb={5}>
                                    <MKTypography
                                        color="Black"
                                        sx={{
                                            fontSize: "1.2rem",
                                            lineHeight: "1.2",
                                            textAlign: "center",
                                            fontWeight: 700,
                                            mt: 0.5,
                                        }}
                                    >
                                        Automated Chiller Regulation
                                    </MKTypography>
                                    <Divider
                                        style={{
                                            margin: '10px auto',
                                            width: '30%',
                                            height: '3px',
                                            backgroundColor: '#43abc1',
                                        }}
                                    />
                                    <MKBox sx={{
                                        px: { xs: 2, md: 1 },
                                        pt: 2,
                                        pb: 1,
                                        backgroundColor: 'rgba(217, 232, 238, 0.8)',
                                        height: { xs: 'auto', md: '180px' },
                                    }}>
                                        <MKTypography
                                           variant="body2"
                                           color="text"
                                           sx={{
                                               fontSize: "1rem",
                                               lineHeight: "1.5",
                                               textAlign: "center",
                                               mt: { xs: 0, md: 3 },
                                               ml: 'auto',
                                               mr: 'auto',
                                               maxWidth: '100%',
                                               width: 'auto',
                                           }}
                                        >
                                            The server uses AI-driven predictions to instruct the chiller controller via WISP, autonomously adjusting operations to maintain optimal performance and energy efficiency.                                            </MKTypography>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={5} mt={4} sx={{ ml: "auto" }}>
                                <MKBox mb={5}>
                                    <MKTypography
                                        color="Black"
                                        sx={{
                                            fontSize: "1.2rem",
                                            lineHeight: "1.2",
                                            textAlign: "center",
                                            fontWeight: 700,
                                            mt: 0.5,
                                        }}
                                    >
                                        Remote System Management
                                    </MKTypography>
                                    <Divider
                                        style={{
                                            margin: '10px auto',
                                            width: '30%',
                                            height: '3px',
                                            backgroundColor: '#43abc1',
                                        }}
                                    />
                                    <MKBox sx={{
                                        px: { xs: 2, md: 1 },
                                        pt: 2,
                                        pb: 1,
                                        backgroundColor: 'rgba(217, 232, 238, 0.8)',
                                        height: { xs: 'auto', md: '180px' },
                                    }}>
                                        <MKTypography
                                             variant="body2"
                                             color="text"
                                             sx={{
                                                 fontSize: "1rem",
                                                 lineHeight: "1.5",
                                                 textAlign: "center",
                                                 mt: { xs: 0, md: 3 },
                                                 ml: 'auto',
                                                 mr: 'auto',
                                                 maxWidth: '100%',
                                                 width: 'auto',
                                             }}
                                        >
                                            Facility managers can monitor and manage the entire system remotely via a user-friendly web-based dashboard, ensuring efficient operations and making informed, data-driven decisions from any location.
                                        </MKTypography>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Container>
                </MKBox >
            </Card >
            <Counters />
            <MKBox pt={0} px={0} mt={0}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default IotPage;
