import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from '@mui/material/Divider';
import MKButton from 'components/MKButton';
import WhatsappChat from "whatsapp/WhatsappChat.js";

import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com'; // Import EmailJS
import Alert from '@mui/material/Alert';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navigation/Navbar";
import DefaultFooter from "examples/Footers/Footer/footer";

import routes from "routes";
import footerRoutes from "footer.routes";

import Whole from "assets/images/Pics _Chiller/thingsboards.png";
import TopPic from "assets/images/backMaximize.jpg"
import CranePic from "assets/images/logos/removed/Crane Logo.png"

const imageStyles = {
  maxWidth: "500px",
  height: "300px",
  objectFit: "contain",
  margin: "auto", // Center the image on mobile
  display: "block", // Ensure the image is a block element for proper centering
  '@media (max-width: 768px)': { // For mobile devices
    maxWidth: "310px", // Ensure the image fits within the container
    height: "210px", // Maintain aspect ratio on mobile
    objectFit: "cover", // Cover the container without stretching
    // padding: "10px", // Add some padding around the image for a cleaner look on mobile
  }
};

function Crane() {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    email: '',
    message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      email: formData.email,
      message: formData.message
    };

    emailjs.send('service_m7kd5pf', 'template_8btqyep', templateParams, '1bmxxZrEdC65PCJD9')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        // Reset form data after successful submission
        setFormData({
          name: '',
          subject: '',
          email: '',
          message: ''
        });
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 5000);
      }, (err) => {
        console.log('Failed to send email. Error: ', err);
      });
  };
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox position="relative" width="100%" height="55vh" display="flex" alignItems="center" justifyContent="center">
        <img
          src={TopPic}
          alt="Top Image"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
        <MKBox
          component="img"
          src={CranePic} // Replace with your image source
          alt="Our Specializations"
          sx={{
            position: "absolute",
            width: { xs: "70%", sm: "50%", md: "30%" }, // Responsive image width
            height: "auto", // Maintain aspect ratio
            mt: 8,
            textAlign: "center",
            objectFit: "cover", // Ensure the image covers the container appropriately
          }}
        />
      </MKBox>
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: -10,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          borderRadius: 0,
        }}
      >
        <Container>
          {/* Row 1: Text (left) - Spacer - Image (right) */}
          <Grid container alignItems="center" spacing={4} mt={8}>
            <Grid item xs={12} md={5} >
              <MKBox mb={5}>
                <MKTypography
                  variant="h3"
                  textAlign={{ xs: 'center', md: 'left' }} // Center text on mobile, left on desktop
                  mb={1}
                  ml={{xs:0 , md:2 }}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    [breakpoints.down("md")]: {
                      fontSize: size["2xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["xl"], // Slightly smaller on very small screens
                    },
                    maxWidth: '100%',
                  })}
                >
                  Comprehensive Real-Time Analysis and Network Efficiency
                </MKTypography>
                <Divider
                  sx={{
                    margin: '10px 0',
                    width: { xs: '75%', md: '90%' },
                    height: '3px',
                    backgroundColor: '#43abc1',
                    mx: { xs: 'auto', md: 2 },
                  }}
                />
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  ml={{xs:0 , md:2 }}
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: { xs: 'center', md: 'left' },
                    mt: 3,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                    maxWidth: '100%',
                    ul: {
                      listStyleType: { xs: 'none', md: 'disc' }, // No bullets on mobile, bullets on desktop
                      paddingLeft: { xs: 0, md: '1.5em' }, // Remove left padding on mobile
                    },
                  })}
                >
                  The Crane Monitoring System offers real-time, integrated monitoring and control across industrial sites. It optimizes efficiency with advanced analytics and a centralized dashboard, providing automated reporting and instant issue detection to minimize downtime and enhance performance.
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={false} md={2} /> {/* Spacer in the middle */}
            <Grid item xs={12} md={5} mt={{ xs: -4, md: -4 }} >
              <MKBox>
                <MKBox component="img" sx={imageStyles} src={Whole} alt="Whole" />
              </MKBox>
            </Grid>
          </Grid>

          {/* Row 2: Image (left) - Spacer - Text (right) */}
          <Grid container alignItems="center" spacing={4} mb={5} mt={-1}>
            <Grid item xs={12} md={5} mt={{ xs: 2, md: -4 }} >
              <MKBox>
                <MKBox component="img" sx={imageStyles} src={Whole} alt="Whole" />
              </MKBox>
            </Grid>
            <Grid item xs={false} md={2} /> {/* Spacer in the middle */}
            <Grid item xs={12} md={5} mt={{ xs: 0, md: 6 }}>
              <MKBox mb={5}>
                <MKTypography
                  variant="h3"
                  ml={{xs:0 , md:2 }}
                  textAlign={{ xs: 'center', md: 'left' }} // Center text on mobile, left on desktop
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    [breakpoints.down("md")]: {
                      fontSize: size["2xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["xl"],
                    },
                    maxWidth: '100%',
                  })}
                >
                  Powerful Features Driving Precision and Control
                </MKTypography>
                <Divider
                  sx={{
                    margin: '10px 0',
                    width: { xs: '75%', md: '90%' },
                    height: '3px',
                    backgroundColor: '#43abc1',
                    mx: { xs: 'auto', md: 2 },
                  }}
                />
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  ml={{xs:0 , md:2 }}
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: { xs: 'center', md: 'left' },
                    mt: 3,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                    maxWidth: '100%',
                    ul: {
                      listStyleType: { xs: 'none', md: 'disc' }, // No bullets on mobile, bullets on desktop
                      paddingLeft: { xs: 0, md: '1.5em' }, // Remove left padding on mobile
                    },
                  })}
                >
                 With real-time tracking of critical devices like RHT sensors and RTDs, the Crane Monitoring System delivers customizable alerts for immediate anomaly detection. It features interactive data visualization and historical analysis, supporting predictive maintenance and scalable integration as operations expand.
                 </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
          <Grid container alignItems="center">
            <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 6 } }}>
              <MKTypography
                variant="h3"
                mt={0}
                mb={1}
                ml={{xs:0 , md:2 }}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#59535c",
                  textAlign: { xs: 'center', md: 'left' }, // Center text on mobile, left on desktop
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["2xl"],
                  },
                })}>Contact Us</MKTypography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                />
                <MKButton type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: '#59535c',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#59535c',
                    },
                    textTransform: 'none',
                    fontWeight: 'bold',
                    display: 'block', // Ensure this is set to 'block' or 'inline-block'
                    visibility: 'visible',
                    opacity: 1,
                    zIndex: 1000,
                    mt: {xs:2, md:1},
                    mx: { xs: 'auto', md: 0 },
                    ml: { xs: 'auto', md: 0 },
                  }}>
                  SUBMIT
                </MKButton>
                {alertVisible && <Alert severity="success">There you go! Your email is on its way to SquareCloud</Alert>}
              </form>
            </Grid>
          </Grid>
        </Container>
      </Card>
      <MKBox pt={5} px={0} mt={5}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <WhatsappChat />
    </>
  );
}

export default Crane;
