import React from 'react';
import 'pages/Hero/HeroSection.css';
import video from 'assets/videos/hero-bg.mp4'

function HeroSection() {
  return (
    <div className="hero-container">
      <video className="hero-video" loop autoPlay muted playsInline>
        <source src={video} type="video/mp4" />
        Switch to Google Chrome to view.
      </video>
      <div className="hero-overlay">
        <h1>Autonomous Building Intelligence Striving <br/>towards Zero Energy Footprints </h1>
        <p>Reduce Energy Consumption. Build Resilience. Decarbonize to Zero - Scaling Sustainability and Save BIG</p>
      </div>
    </div>
  );
}

export default HeroSection;
