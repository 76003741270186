import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';

// Import images from the assets folder
import IMG_20240904_WA0009 from "assets/images/Newsletter/IMG-20240904-WA0009.jpg";
import IMG_20240904_WA0010 from "assets/images/Newsletter/IMG-20240904-WA0010.jpg";
import IMG_20240904_WA0011 from "assets/images/Newsletter/IMG-20240904-WA0011.jpg";
import IMG_20240904_WA0012 from "assets/images/Newsletter/IMG-20240904-WA0012.jpg";
import IMG_20240904_WA0013 from "assets/images/Newsletter/IMG-20240904-WA0013.jpg";
import IMG_20240904_WA0014 from "assets/images/Newsletter/IMG-20240904-WA0014.jpg";
import IMG_20240904_WA0015 from "assets/images/Newsletter/IMG-20240904-WA0015.jpg";
import IMG_20240904_WA0016 from "assets/images/Newsletter/IMG-20240904-WA0016.jpg";
import IMG_20240904_WA0017 from "assets/images/Newsletter/IMG-20240904-WA0017.jpg";
import IMG_20240904_WA0018 from "assets/images/Newsletter/IMG-20240904-WA0018.jpg";
import IMG_20240904_WA0019 from "assets/images/Newsletter/IMG-20240904-WA0019.jpg";
import IMG_20240904_WA0020 from "assets/images/Newsletter/IMG-20240904-WA0020.jpg";
import IMG_20240904_WA0021 from "assets/images/Newsletter/IMG-20240904-WA0021.jpg";
import IMG_20240904_WA0022 from "assets/images/Newsletter/IMG-20240904-WA0022.jpg";
import IMG_20240904_WA0023 from "assets/images/Newsletter/IMG-20240904-WA0023.jpg";
import IMG_20240904_WA0024 from "assets/images/Newsletter/IMG-20240904-WA0024.jpg";
import IMG_20240904_WA0025 from "assets/images/Newsletter/IMG-20240904-WA0025.jpg";
import IMG_20240904_WA0026 from "assets/images/Newsletter/IMG-20240904-WA0026.jpg";
import IMG_20240904_WA0027 from "assets/images/Newsletter/IMG-20240904-WA0027.jpg";
import IMG_20240904_WA0028 from "assets/images/Newsletter/IMG-20240904-WA0028.jpg";

const Slideshow = ({ images, selectedIndex, onSlideChange }) => {
  return (
    <div style={{ height: 'auto' }}>
      <Carousel
        selectedItem={selectedIndex}
        onChange={onSlideChange}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        autoPlay={false}
        interval={3000}
        stopOnHover
        dynamicHeight={false}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              height: 'auto',
              borderRadius: '0px',  // Apply border radius for rounded corners
              overflow: 'hidden'
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: '0px',  // Round the images
              }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

// Prop validation for Slideshow
Slideshow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSlideChange: PropTypes.func.isRequired,
};

function Newsletter() {
  // State to track the current slide index
  const [currentSlide, setCurrentSlide] = useState(0);

  // Sync both slideshows
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % leftSlideshowImages.length); // Update to next slide
    }, 10000);  // Change slides every 10 seconds

    return () => clearInterval(intervalId); // Cleanup the interval
  }, []);

  // Images for the slideshows
  const leftSlideshowImages = [
    IMG_20240904_WA0009, IMG_20240904_WA0010, IMG_20240904_WA0011,
    IMG_20240904_WA0012, IMG_20240904_WA0013, IMG_20240904_WA0014,
    IMG_20240904_WA0015, IMG_20240904_WA0016, IMG_20240904_WA0017,
    IMG_20240904_WA0018, IMG_20240904_WA0019, IMG_20240904_WA0020,
    IMG_20240904_WA0021, IMG_20240904_WA0022
  ];

  const rightSlideshowImages = [
    IMG_20240904_WA0023, IMG_20240904_WA0024, IMG_20240904_WA0025,
    IMG_20240904_WA0026, IMG_20240904_WA0027, IMG_20240904_WA0028
  ];

  return (
    <Grid container spacing={2} sx={{ backgroundColor: "#d9e8ee", mb: { xs: -4, md: -4 }, p: { xs: 2.5, md: 4, lg: 6 } }}>
      <Grid item xs={12} md={6} >
        <MKBox
          sx={{
            p: { xs: 2, md: 4, lg: 5 },
            mt: { xs: -4, md: 0 },
            borderRadius: "0px",
            height: { xs: 'auto', md: '550px', lg: '700px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden', // Prevent overflow
          }}
        >
          <Slideshow
            images={leftSlideshowImages}
            selectedIndex={currentSlide}
            onSlideChange={(index) => setCurrentSlide(index)}
          />
          <Grid item xs={12} md={12} mt={{ xs: 0, md: 2 }}>
            <MKTypography
              variant="h4"
              textAlign="center"
              mt={0}
              mb={1}
              ml={2}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["xl"],
                },
              })}
            >
              Tour by CEO of Newcastle University
            </MKTypography>
            <MKTypography
              variant="body1" 
              textAlign="center"
              mt={0}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["xl"], // Smaller font on mobile
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["lg"],
                },
              })}
            >
              Visitors from Newcastle University Medicine Malaysia: Mr Dennis Wong, CEO, and Mr. Jeffrey Cheng, Senior Facilities Manager, arrived at Mimos for a tour on Mimos.
            </MKTypography>
          </Grid>
        </MKBox>
      </Grid>

      <Grid item xs={12} md={6}>
        <MKBox
          sx={{
            p: { xs: 2, md: 4, lg: 5 },
            borderRadius: "0px",
            height: { xs: 'auto', md: '550px', lg: '700px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
          }}
        >
          <Slideshow
            images={rightSlideshowImages}
            selectedIndex={currentSlide}
            onSlideChange={(index) => setCurrentSlide(index)}
          />
          <Grid item xs={12} md={12} mt={{ xs: 0, md: 2 }}>
            <MKTypography
              variant="h4"
              textAlign="center"
              mt={0}
              mb={1}
              ml={2}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["xl"], // Smaller font on mobile
                },
              })}
            >
              MiAce Fab Chiller Control System
            </MKTypography>
            <MKTypography 
              variant="body1" 
              textAlign="center"
              mt={0}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["xl"], // Adjust font size for mobile
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["lg"],
                },
              })}
            >
              The MiAce chiller control system optimizes energy efficiency and supports the goal of achieving net-zero energy usage in buildings. This visit included a comprehensive review of the system.
            </MKTypography>
          </Grid>
        </MKBox>
      </Grid>
    </Grid>
  );
}

export default Newsletter;
