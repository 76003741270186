import React from 'react';
import { Container, Grid } from '@mui/material';
import MKTypography from 'components/MKTypography';
import MKBox from 'components/MKBox';
// images
import Mission from "assets/images/logos/mission.png";
//import CoreValue from "assets/images/logos/corevalue.png";
import Vission from "assets/images/logos/Vision.png";

const MissionVision = () => {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={12} md={8} mt={-4} textAlign="center">
            <MKBox mb={2}>
              <MKBox component="img" src={Mission} alt="mission" width="10%" />
            </MKBox>
            <MKTypography
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["3xl"],
                },
              })}
              variant="h4"
              mb={3}
            >
              Our Vision:
            </MKTypography>
            <MKTypography
              variant="body2"
              color="text"
              sx={{
                fontSize: "1.2rem",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              To revolutionize the global building industry by creating intelligent, AI-driven environments that achieve carbon neutrality, empowering businesses to embrace sustainability and shaping a greener future for all.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={8} mt={3} textAlign="center">
            <MKBox mb={2}>
              <MKBox component="img" src={Vission} alt="vision" width="10%" />
            </MKBox>
            <MKTypography
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["2xl"],
                },
              })}
              variant="h4"
              mb={3}
            >
              Our Mission:
            </MKTypography>
            <MKTypography
              variant="body2"
              color="text"
              sx={{
                fontSize: "1.2rem",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
             We develop cutting-edge IoT sensors and AI-driven platforms to aggregate and analyze building energy data, enabling businesses to reduce energy consumption, optimize performance, and achieve their sustainability goals. Through innovative technology, data intelligence, and proactive energy management solutions, we empower companies to transition toward carbon-neutral operations and contribute to a sustainable future.
            </MKTypography>
          </Grid>
          {/* 
          <Grid item xs={12} md={8} mb={5} mt={3} textAlign="center">
            <MKBox mb={2}>
              <MKBox component="img" src={CoreValue} alt="core values" width="10%" />
            </MKBox>
            <MKTypography
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["2xl"],
                },
              })}
              variant="h4"
              mb={3}
            >
              Our Core Values:
            </MKTypography>
            <MKTypography
              variant="body2"
              color="text"
              sx={{
                fontSize: "1.2rem",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              To transform the building industry through AI-driven IOT technology, empowering building owners to achieve autonomous, carbon-neutral energy management and optimization.
            </MKTypography>
          </Grid>
          */}
        </Grid>
      </Container>
    </MKBox>
  );
};

export default MissionVision;
