import { useEffect } from "react";

// react-router components
import { Routes, Route,Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import ContactUs from "pages/LandingPages/ContactUs";
import Services from "pages/LandingPages/Services";
import Maximizee from "pages/LandingPages/Services/components/pages/Maximizee";
import Crane from "pages/LandingPages/Services/components/pages/Crane";
import Intellisenz from "pages/LandingPages/Services/components/pages/Intellisenz";
import GreenBOS from "pages/LandingPages/Services/components/pages/GreenBOS";
import Monitoring from "pages/LandingPages/Services/components/pages/monitoring";
import IotPage from "pages/LandingPages/Services/components/pages/iotPage";
import VisionMission from "layouts/pages/landing-pages/ManagementTeam";
import OurCoreValues from "layouts/pages/landing-pages/OurCoreValues";
import AboutUS from "pages/LandingPages/AboutUs";
import OurPartner from "pages/LandingPages/Partner";

// Material Kit 2 React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/VisionMission" element={<VisionMission />} />
        <Route path="/OurCoreValues" element={<OurCoreValues />} />
        <Route path="/Maximizee" element={<Maximizee />} />
        <Route path="/Crane" element={<Crane />} />
        <Route path="/Intellisenz" element={<Intellisenz />} />
        <Route path="/GreenBOS" element={<GreenBOS />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/monitoring" element={<Monitoring />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/iotPage" element={<IotPage />} />
        <Route path="/AboutUS" element={<AboutUS />} />
        <Route path="/ourPartner" element={<OurPartner />} /> 
        <Route path="*" element={<Navigate to="/presentation" />} />
      </Routes>
    </ThemeProvider>
);
}