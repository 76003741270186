import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Box, Grid, List, ListItem, ListItemText, Typography, Button, Divider } from '@mui/material';

// Sample sidebar items and their corresponding content
const sidebarItems = [
  {
    title: 'Energy Management',
    content: `
      <p>Our <strong>Energy Management Services</strong> provide a comprehensive, systematic approach to optimizing energy efficiency for buildings and plants. Through an in-depth <strong>Energy Audit Process</strong>, we use advanced analytics to assess overall system performance and establish key efficiency and benchmark indices.</p>
      <br />
      <p>By leveraging proven <strong>“Know-How” practices</strong> and cutting-edge technologies, we identify key areas for improvement across multiple verticals, equipment, and systems. This approach reduces energy wastage and maximizes efficiency, ensuring a sustainable and cost-effective energy management solution.</p>
    `,
  },
  {
    title: 'Efficiency Services',
    content: `
      <p>A comprehensive Energy Audit for commercial or industrial buildings can be conducted to verify benchmark efficiencies and indices.</p>
      <br />
      <p>Our <strong>Efficiency Services</strong> focus on optimizing equipment performance through continuous data analytics and monitoring. By systematically tracking the input resource utilization and output performance of specific equipment, we analyze variations in combined energy usage to identify inefficiencies in the resource consumption of the specific equipment.</p>
      <br />
      <p>This real-time monitoring allows us to validate performance, optimize resource allocation, and implement strategies to achieve maximum efficiency and energy savings.</p>
    `,
  },
  {
    title: 'AI and IOT Solutions',
    content: `
      <p>Our AI and IoT solutions leverage intelligent systems and connectivity to automate processes and equipment.</p>
      <br />
      <p>These technologies empower stakeholders by providing real-time data and insights, enhancing decision-making, and enabling more effective management of building operations.</p>
    `,
  },
  {
    title: 'Chiller MaximizEE',
    content: `
      <p>Chiller optimization enhances the performance of cooling systems to reduce energy consumption and operational costs.</p>
    `,
  }
];

function WhatWeDo() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fade, setFade] = useState(false);

  // Handle sidebar item click
  const handleListItemClick = (index) => {
    setFade(false); // Start fade out
    setTimeout(() => {
      setSelectedIndex(index);
      setFade(true); // Start fade in
    }, 400); // Duration matches CSS transition time
  };

  // Apply fade-in effect on component mount
  useEffect(() => {
    setFade(true);
  }, []);

  return (
    <Box sx={{ p: { xs: 3, md: 2 }, mt: { xs: 4, md: 13 }, width: '100%', backgroundColor: '#d9e8ee', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container spacing={3} sx={{ maxWidth: '1200px', width: '100%', ml: { xs: -2, md: 12 } }}>
        {/* Sidebar */}
        <Grid item xs={12} md={3}>
          <Box sx={{ p: { xs: 1, md: 2 } }}>
            <Typography variant="h4"
              textAlign="left"
              mt={0}
              mb={1}
              ml={2}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["xl"],
                },
              })}
            >
              SquareCloud
            </Typography>
            <Divider
              sx={{
                margin: { xs: '15px 15px', md: '10px 15px' }, // Adjust margins for mobile vs desktop
                width: { xs: '50%', md: '70%' },        // Different width for mobile
                height: { xs: '2px', md: '3px' },       // Different height for mobile
                backgroundColor: '#59535c',
              }}
            />
            <List component="nav" sx={{ pt: 0, mb: 1, backgroundColor: '#43abc1' }}>
              {sidebarItems.map((item, index) => (
                <ListItem
                  key={index}
                  button
                  selected={selectedIndex === index}
                  onClick={() => handleListItemClick(index)}
                  sx={{
                    textAlign: 'left',
                    position: 'relative',
                    borderRadius: '0px',
                    mb: 0,
                    backgroundColor: selectedIndex === index ? '#43abc1' : '#d9e8ee',
                    height: '60px', // ribbon size
                    color: selectedIndex === index ? '#fff' : '#333', // font color
                    '&:hover': {
                      backgroundColor: selectedIndex === index ? '#43abc1' : '#f1f1f1',
                    },
                    '&::before': {
                      content: selectedIndex === index ? '""' : 'none',
                      position: 'absolute',
                      top: '50%',
                      right: '-30px',
                      transform: 'translateY(-50%)',
                      borderWidth: '30px 0 30px 30px',
                      borderStyle: 'solid',
                      borderColor: 'transparent transparent transparent #3ca0b8',
                      '@media (max-width: 768px)': {
                        display: 'none', // Hide pseudo-element on mobile
                      },
                    },
                  }}
                >
                  <ListItemText primary={item.title} sx={{ zIndex: 3, textAlign: 'left', paddingLeft: '16px' }} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        {/* Main Content */}
        <Grid item xs={12} md={9}>
          <Box sx={{ p: { xs: 2, md: 3 }, transition: 'opacity 0.3s ease', opacity: fade ? 1 : 0 }}>
            <Typography variant="h4" textAlign="left"
              mt={0}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["xl"],
                },
              })}>
              {sidebarItems[selectedIndex].title}
            </Typography>
            <Typography variant="body2"
              color="#59535c"
              mb={0}
              sx={({ breakpoints }) => ({
                fontSize: "1.1rem",
                lineHeight: "1.5",
                textAlign: "left",
                mt: 4,
                maxWidth: 750,
                [breakpoints.down("md")]: {
                  fontSize: "1rem",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              })}
              dangerouslySetInnerHTML={{ __html: sidebarItems[selectedIndex].content }}
            />
            <Link to={selectedIndex === 0 || selectedIndex === 2 ? "/Maximizee" : "/Services"} style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  backgroundColor: '#43abc1',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#43abc1',
                  },
                  textTransform: 'none',
                  fontWeight: 'bold',
                  fontSize: { xs: '0.9rem', md: '0.9rem' }, // Adjust button font size for mobile
                  padding: { xs: '6px 12px', md: '7.5px 15px' }, // Adjust button padding for mobile
                }}
              >
                LEARN MORE
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box >
  );
}

export default WhatWeDo
