import React, { useState, useEffect } from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { FaRobot } from "react-icons/fa";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles
import config from "config.js";
import MessageParser from "chatbot/MessageParser.js";
import ActionProvider from "chatbot/ActionProvider.js";
import WhatsappChat from "whatsapp/WhatsappChat.js";
import Card from '@mui/material/Card';
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navigation/Navbar";
import DefaultFooter from "examples/Footers/Footer/footer";

// Components
import Energy from "pages/LandingPages/Services/components/Energy"; // MaximizEE
import Monitoring from "pages/LandingPages/Services/components/dryair"; // CranE
import Iot from "pages/LandingPages/Services/components/Iot"; // Intellisenz
import GreenBos from "pages/LandingPages/Services/components/greenbos"; // GreenBOS

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import TopPic from "assets/images/partners/servicesBack.jpg";

function Services() {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const toggleChatbot = () => {
    setIsChatbotOpen((prevState) => !prevState);
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />

      {/* Hero Section */}
      <MKBox
        position="relative"
        width="100%"
        height={{ xs: "30vh", md: "60vh" }} // Responsive height
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={TopPic}
          alt="Top Image"
          style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "center" }}
        />
        <MKTypography
          variant="h1"
          component="div"
          color="white"
          sx={{
            position: "absolute",
            fontSize: { xs: "2rem", sm: "3rem", md: "4.5rem" }, // Responsive font size
            fontWeight: "bold",
            textAlign: "center",
            textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
            px: 2, // Padding to prevent text overflow
          }}
        >
          Energy Optimization Services
        </MKTypography>
      </MKBox>

      {/* Main Content */}
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: -10,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          borderRadius: 0,
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 5,
          }}
        >
          {/* Energy Section (MaximizEE) */}
          <MKBox
            data-aos="fade-left"
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", md: "1200px" }, // Full width on mobile
              height: { xs: "auto", md: "600px" }, // Auto height for mobile, fixed for larger screens
              my: { xs: 2, md: 3 }, // Increased vertical margin for spacing
              p: { xs: 2, md: 2 }, // Reduced padding for mobile
              backgroundColor: "white",
              borderRadius: "8px",
              display: "flex", // Flexbox to center content
              flexDirection: { xs: "column", md: "row" }, // Stack content vertically on mobile
              alignItems: "center", // Vertically center content
              justifyContent: "center", // Horizontally center content
            }}
          >
            <Energy />
          </MKBox>

          {/* Intellisenz Section */}
          <MKBox
            data-aos="fade-right"
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", md: "1200px" }, // Full width on mobile
              height: { xs: "auto", md: "600px" }, // Auto height for mobile, fixed for larger screens
              my: { xs: 2, md: 3 }, // Increased vertical margin for spacing
              p: { xs: 2, md: 2 }, // Reduced padding for mobile
              backgroundColor: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" }, // Stack content vertically on mobile
              justifyContent: "space-between",
            }}
          >
            <Iot />
          </MKBox>

          {/* CranE Section */}
          <MKBox
            data-aos="fade-left"
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", md: "1200px" }, // Full width on mobile
              height: { xs: "auto", md: "600px" }, // Auto height for mobile, fixed for larger screens
              my: { xs: 2, md: 3 }, // Increased vertical margin for spacing
              p: { xs: 2, md: 2 }, // Reduced padding for mobile
              backgroundColor: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" }, // Stack content vertically on mobile
              justifyContent: "space-between",
            }}
          >
            <Monitoring />
          </MKBox>

          {/* GreenBos Section */}
          <MKBox
            data-aos="fade-right"
            sx={{
              width: "100%",
              maxWidth: { xs: "100%", md: "1200px" }, // Full width on mobile
              height: { xs: "auto", md: "600px" }, // Auto height for mobile, fixed for larger screens
              my: { xs: 2, md: 3 }, // Increased vertical margin for spacing
              p: { xs: 2, md: 2 }, // Reduced padding for mobile
              backgroundColor: "white",
              borderRadius: "8px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" }, // Stack content vertically on mobile
              justifyContent: "space-between",
            }}
          >
            <GreenBos />
          </MKBox>
        </Container>

        {/* Chatbot Button */}
        <MKBox
          sx={{
            position: "fixed",
            bottom: { xs: 15, md: 20 },
            left: { xs: 15, md: 20 },
            zIndex: 1000,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            backgroundColor: "#007BFF",
            borderRadius: "50%",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s ease-in-out",
            '&:hover': {
              transform: "scale(1.1)"
            }
          }}
          onClick={toggleChatbot}
        >
          <FaRobot color="white" size="24px" />
        </MKBox>

        {isChatbotOpen && (
          <div
            id="chatbot-container"
            style={{
              position: "fixed",
              bottom: "80px",
              left: "20px",
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              padding: "10px",
              width: "calc(100% - 40px)", // Responsive width for chatbot
              maxWidth: "400px", // Limit max width for larger screens
              height: "400px", // Set a consistent height
              overflow: "hidden", // Hide overflow if the content is too large
            }}
          >
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        )}
      </Card>

      {/* Footer */}
      <MKBox pt={5} px={0} mt={5}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

      <WhatsappChat />
    </>
  );
}

export default Services;
