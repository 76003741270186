import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  // State to manage expanding content
  const [expandInnovating, setExpandInnovating] = useState(false);
  const [expandCommitment, setExpandCommitment] = useState(false);

  // Toggle functions for expanding/collapsing content
  const toggleExpandInnovating = () => setExpandInnovating(!expandInnovating);
  const toggleExpandCommitment = () => setExpandCommitment(!expandCommitment);

  return (
    <MKBox component="section" py={8}>
      <Container>
        <Grid container spacing={2} alignItems="center">
          {/* Header Section */}
          <Grid item xs={12} lg={12} mt={-2}>
            <MKBox mb={5}>
              <MKTypography
                variant="h3"
                textAlign="center"
                mt={4}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#59535c",
                  fontFamily: "'Caudex', serif",
                  fontWeight: "bold",
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                Delivering Lean Autonomous Intelligent Buildings
              </MKTypography>
            </MKBox>
          </Grid>

          {/* Main Content Section */}
          <Grid item xs={12} lg={12} mt={4}>
            <MKBox mb={5}>
              <MKTypography
                variant="body2"
                color="#59535c"
                mb={0}
                sx={({ breakpoints }) => ({
                  fontSize: "1.2rem",
                  lineHeight: "1.75",
                  textAlign: "center",
                  mt: 0,
                  [breakpoints.down("md")]: {
                    fontSize: "1rem",
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: "1rem",
                  },
                })}
              >
                Our Journey: The Dream Behind Squarecloud
                <br /><br />
                Founded in 2016, Squarecloud was born from a vision to empower building managers and owners through digital transformation. Our mission is to unlock the full potential of building and service performance by integrating cutting-edge technology and data-driven solutions.
                <br /><br />
                The name Squarecloud symbolizes our core values: Square represents the buildings we optimize, while Cloud signifies the digital transformation and analytics we harness to scale efficiency.
                <br /><br />
                As we grew, Squarecloud evolved into a consultancy, guiding clients on optimizing plant, building, and equipment efficiencies. Our focus became identifying inefficiencies, particularly in energy consumption, and helping businesses reduce waste and improve performance.
              </MKTypography>
            </MKBox>
            <Divider
              sx={{
                margin: '50px 0px',
                width: '100%',
                height: '3px',
                backgroundColor: '#43abc1',
              }}
            />
          </Grid>

          {/* Innovating for a Sustainable Future */}
          <Grid item xs={12} lg={5} mt={0}>
            <MKBox
              mb={5}
              sx={{
                background: 'linear-gradient(135deg, #d9e8ee 30%, #43abc1 100%)',
                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
                borderRadius: '16px',
                padding: { xs: 3, md: 5 },
                height: expandInnovating || expandCommitment ? 'auto' : '500px', // Fixed height
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                },
              }}
            >
              <MKTypography
                variant="h3"
                textAlign="center"
                mt={0}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#504f59",
                  fontFamily: "'Caudex', serif",
                  fontWeight: "bold",
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                Innovating for a Sustainable Future
              </MKTypography>
              <MKBox
                sx={{
                  px: { xs: 2, md: 1 },
                  pt: 2,
                  pb: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(6px)',
                  borderRadius: '12px',
                  height: '300px', // Consistent height for both boxes
                  overflowY: expandInnovating ? 'auto' : 'hidden', // Scrollable content when expanded
                }}
              >
                <MKTypography
                  variant="body2"
                  color="#333"
                  sx={{
                    fontSize: "1.1rem",
                    lineHeight: "1.75",
                    textAlign: "center",
                    mx: 'auto',
                    maxWidth: '90%',
                    width: '400px',
                  }}
                >
                  {expandInnovating ? (
                    <>
                      By 2019, Squarecloud became a System Integrator, leveraging IoT and software to monitor and address inefficiencies in real-time. We developed our proprietary IoT platform, allowing for standardized, efficient, and scalable implementations.
                      <br /><br />
                      Since 2020, we’ve expanded into IoT monitoring and control systems across multiple industries, focusing on Smart Chiller systems.
                    </>
                  ) : (
                    "By 2019, Squarecloud became a System Integrator, leveraging IoT and software to monitor and address inefficiencies in real-time."
                  )}
                </MKTypography>
              </MKBox>
              <Button
                onClick={toggleExpandInnovating}
                sx={{
                  display: 'block',
                  margin: '10px auto',
                  backgroundColor: "#43abc1",
                  color: "#fff",
                  '&:hover': {
                    backgroundColor: "#36a2b3",
                  },
                  textTransform: 'none',
                }}
              >
                {expandInnovating ? "SHOW LESS" : "SHOW MORE"}
              </Button>
            </MKBox>
          </Grid>

          {/* Spacer */}
          <Grid item xs={12} lg={2} />

          {/* Our Commitment to Sustainability and Innovation */}
          <Grid item xs={12} lg={5} mt={0}>
            <MKBox
              mb={5}
              sx={{
                background: 'linear-gradient(135deg, #43abc1 30%, #ffffff 100%)',
                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
                borderRadius: '16px',
                padding: { xs: 3, md: 5 },
                height: expandInnovating || expandCommitment ? 'auto' : '500px', // Fixed height
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-10px)',
                },
              }}
            >
              <MKTypography
                variant="h3"
                textAlign="center"
                mt={0}
                mb={2}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#504f59",
                  fontFamily: "'Caudex', serif",
                  fontWeight: "bold",
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                Our Commitment to Sustainability
              </MKTypography>
              <MKBox
                sx={{
                  px: { xs: 2, md: 1 },
                  pt: 2,
                  pb: 2,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(6px)',
                  borderRadius: '12px',
                  height: '300px', // Consistent height for both boxes
                  overflowY: expandCommitment ? 'auto' : 'hidden', // Scrollable content when expanded
                }}
              >
                <MKTypography
                  variant="body2"
                  color="#333"
                  sx={{
                    fontSize: "1.1rem",
                    lineHeight: "1.75",
                    textAlign: "center",
                    mx: 'auto',
                    maxWidth: '90%',
                    width: '400px',
                  }}
                >
                  {expandCommitment ? (
                    <>
                      At SquareCloud, we are dedicated to creating a carbon-neutral future through innovation and collaboration. 
                      By harnessing advanced IoT and AI technologies, we help businesses optimize energy use and reduce environmental impact. 
                      <br/><br/>
                      Together, we set new standards for intelligent building operations, driving sustainability and operational excellence.
                    </>
                  ) : (
                    "At SquareCloud, we are dedicated to creating a carbon-neutral future through innovation and collaboration."
                  )}
                </MKTypography>
              </MKBox>
              <Button
                onClick={toggleExpandCommitment}
                sx={{
                  display: 'block',
                  margin: '10px auto',
                  backgroundColor: "#43abc1",
                  color: "#fff",
                  '&:hover': {
                    backgroundColor: "#36a2b3",
                  },
                  textTransform: 'none',
                }}
              >
                {expandCommitment ? "SHOW LESS" : "SHOW MORE"}
              </Button>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
