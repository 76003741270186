// @mui material components
//import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//imags
import Automated from "assets/images/partners/automated.jpg"

const imageStyles1 = {
  maxWidth: "100%",
  height: "auto",
  objectFit: "contain",
  mx: 1,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1)",
  },
};

function Information() {
  return (
    <Grid container alignItems="center" mt={10}>
        <Grid container alignItems="left">
          <Grid item xs={12} md={7} mt={{ xs: 4, sm: 4, md: 14 }}>
            <MKTypography
              variant="h3"
              color="unset"
              textAlign="left"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                textAlign: {
                  xs: "center", // Center on mobile
                  md: "left",   // Left-align on medium and larger screens
                },
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["2xl"],
                },
              })}
            >
              ZERO CAPEX: Savings-as-a-Service for Chiller Plant Optimization
            </MKTypography>
            <Divider
              sx={{
                margin: { xs: '20px auto', md: '10px 0' }, // Adjust margins for mobile vs desktop
                width: { xs: '50%', md: '30%' },        // Different width for mobile
                height: { xs: '2px', md: '3px' },       // Different height for mobile
                backgroundColor: '#43abc1',
              }}
            />
            <MKTypography
              variant="body2"
              color="unset"
              mb={0}
              sx={({ breakpoints }) => ({
                fontSize: "1.2rem",
                lineHeight: "1.5",
                textAlign: {
                  xs: "center", // Center on mobile
                  md: "left",   // Left-align on medium and larger screens
                },                mt: 5,
                [breakpoints.down("md")]: {
                  fontSize: "1rem",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              })}
            >
              Our ZERO CAPEX Savings-as-a-Service model revolutionizes the way building owners approach energy efficiency and cost savings. Through advanced engineering, retro-commissioning, and continuous optimization of chiller plants, we deliver substantial energy savings with no upfront capital expenditure.
              <br /><br />
              This innovative method allows building owners to enjoy immediate cost reductions and enhanced operational performance without the complexity or financial burden of traditional upgrades. Our solution ensures seamless integration into existing systems, making it easier than ever to unlock long-term value and sustainability.
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={1}>
          </Grid>
          <Grid item xs={12} md={4} mt={8} sx={{
                ml: { xs: 1, md: 0 }, // Margin-left: 2 on mobile, 0 on desktop
                mr: { xs: 2, md: 0 }, // Margin-right: 2 on mobile, 0 on desktop 
          }}>
            <MKBox mb={5}>
              <MKBox justifyContent="flex-end" component="img" sx={imageStyles1} src={Automated} alt="chiler" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
    </Grid>
  );
}

export default Information;
