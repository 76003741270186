import React, { useState, useEffect, useRef } from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { FaRobot } from "react-icons/fa";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import config from "config.js";
import MessageParser from "chatbot/MessageParser.js";
import ActionProvider from "chatbot/ActionProvider.js";
import WhatsappChat from "whatsapp/WhatsappChat.js";

// @mui/material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Divider from '@mui/material/Divider';

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navigation/Navbar";
import DefaultFooter from "examples/Footers/Footer/footer";

// Presentation page sections
import Information from "pages/Presentation/sections/Information";
import Hero from "pages/Hero/HeroSection";
import Counters from "pages/Presentation/sections/Counters";
import NavServices from "pages/Presentation/sections/NavServices";
import Newsletter from "pages/Presentation/sections/Newsletter";  

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import chiler from "assets/images/Pics _Chiller/chiller.jpg";



const imageStyles1 = {
  maxWidth: "100%",
  height: "auto",
  objectFit: "contain",
  mx: 1,
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1)",
  },
};

function Presentation() {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const textRef = useRef(null);
  

  const toggleChatbot = () => {
    setIsChatbotOpen((prevState) => !prevState);
  };

  const scrollToNextSection = () => {
    const currentScroll = window.scrollY;
    const targetScroll = currentScroll + window.innerHeight;
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });

    // Hide button once clicked and return after 0.5s
    setIsButtonVisible(false);
    setTimeout(() => {
      setIsButtonVisible(true);
    }, 500);
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Hero />
      <DefaultNavbar routes={routes} transparent light />
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container alignItems="center">
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={12} mt={{ xs: 2, sm: 4, md: 14 }}>
                <MKTypography
                  ref={textRef}
                  variant="h3"
                  textAlign="center"
                  mt={4}
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    fontFamily: "'Caudex', serif",
                    fontWeight: "bold",
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["2xl"],
                    },
                  })}
                >
                  &quot;Transforming Energy Efficiency in Buildings for a Sustainable Future&quot;
                </MKTypography>
                <Divider
                  sx={{
                    margin: { xs: '20px auto', md: '10px 0' }, // Adjust margins for mobile vs desktop
                width: { xs: '50%', md: '100%' },        // Different width for mobile
                height: { xs: '2px', md: '3px' },       // Different height for mobile
                backgroundColor: '#43abc1',
                  }}
                />
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  mb={0}
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: "center",
                    mt: 0,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                  })}
                >
                  We Deliver Lean, Autonomous Building Solutions and Improve the Lives of People.
                </MKTypography>
              </Grid>
            </Grid>

          <Grid container alignItems="left">
  <Grid item xs={12} md={7} mt={{ xs: 8, sm: 4, md: 22 }}>
    <MKTypography
      variant="h3"
      textAlign="left"
      mt={0}
      mb={1}
      sx={({ breakpoints, typography: { size } }) => ({
        color: "#59535c",
        textAlign: {
          xs: "center", // Center on mobile
          md: "left",   // Left-align on medium and larger screens
        },
        [breakpoints.down("md")]: {
          fontSize: size["2xl"],
        },
        [breakpoints.down("sm")]: {
          fontSize: size["2xl"],
        },
      })}
    >
      Chiller MaximizEE: AI-Powered Heat Load Analytics for Optimal Energy Efficiency
    </MKTypography>
    <Divider
      sx={{
        margin: { xs: '20px auto', md: '10px 0' }, // Adjust margins for mobile vs desktop
        width: { xs: '50%', md: '30%' },        // Different width for mobile
        height: { xs: '2px', md: '3px' },       // Different height for mobile
        backgroundColor: '#43abc1',
      }}
    />
<MKTypography
  variant="body2"
  color="#59535c" // Dark gray color for text
  mb={4} // Add bottom margin for spacing
  sx={({ breakpoints }) => ({
    fontSize: "1.2rem", // Default font size
    lineHeight: "1.75", // Increase line-height for better readability
    textAlign: {
      xs: "center", // Center text on small screens
      md: "left",   // Left-align text on medium and larger screens
    },
    mt: 4, // Add top margin for spacing
    [breakpoints.down("md")]: {
      fontSize: "1rem", // Smaller font on medium screens
    },
    [breakpoints.down("sm")]: {
      fontSize: "0.875rem", // Even smaller font on small screens
    },
  })}
>
Chiller MaximizEE is an advanced, AI-driven software solution that optimizes the energy consumption of chiller plant facilities in real-time. By continuously analyzing heat load and energy demand, the system ensures that your chiller operates at its ideal &quot;Sweet Spot&quot;&mdash;minimizing energy waste and maximizing efficiency.
  <br /><br />
  With autonomous control, Chiller MaximizEE delivers the precise amount of energy required to meet real-time demand, eliminating unnecessary consumption and reducing operational costs. The platform integrates seamlessly with any existing Building Automation System (BAS) or Facilities Control Management System (FCMS), complementing your current infrastructure for effortless optimization.
</MKTypography>

  </Grid>
  <Grid item xs={12} md={1}>
  </Grid>
  <Grid item xs={12} md={4} mt={{ xs: 6, sm: 4, md: 18 }} sx={{
    ml: { xs: 1, md: 0 }, // Margin-left: 2 on mobile, 0 on desktop
    mr: { xs: 2, md: 0 }, // Margin-right: 2 on mobile, 0 on desktop
  }}>
    <MKBox mb={5}>
      <MKBox justifyContent="flex-end" component="img" sx={imageStyles1} src={chiler} alt="chiler" width="100%" />
    </MKBox>
  </Grid>
</Grid>


          </Container>
          <NavServices />
        </Grid>
      </Card>

      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          borderRadius: 0, // Remove smooth edges
        }}
      >
        <Container>
          <Information />
        </Container>
  {/* Add spacing between Information and Counters */}
  <Box sx={{ mb: { xs: 8, md: 10 } }} /> {/* Add bottom margin to Information */}

{/* Newsletter Section */}
<Grid container> 
  <Grid item xs={12}>
    <Newsletter />
  </Grid>
</Grid>


</Card>

      {isChatbotOpen && (
        <div
          id="chatbot-container"
          style={{
            position: "fixed",
            bottom: "80px",
            left: "20px",
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </div>
      )}
      {isButtonVisible && (
        <MKBox
          sx={{
            position: "fixed",
            bottom: 10,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            borderRadius: "0%",
            boxShadow: "0px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "translateX(-50%) scale(1.1)",
            },
          }}
          onClick={scrollToNextSection}
        >
          <IoChevronDownCircleOutline color="#f9f9f9" size="46px" opacity="100%" />
        </MKBox>
      )}
      <MKBox
        sx={{
          position: "fixed",
          bottom: 20,
          left: 20,
          zIndex: 1000,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "50px",
          height: "50px",
          backgroundColor: "#007BFF",
          borderRadius: "50%",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
        onClick={toggleChatbot}
      >
        <FaRobot color="white" size="24px" />
      </MKBox>
{/* Add top margin to Counters */}
<MKBox mt={{ xs: 8, md: 10 }}>
  <Counters />
</MKBox>
      <MKBox pt={0} px={0} mt={0}>
        <DefaultFooter light content={footerRoutes} />
      </MKBox>
      <WhatsappChat />
    </>
  );
}

export default Presentation;
