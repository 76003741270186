import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import logo from 'assets/images/Pics _Chiller/removed.png';

const values = [
  {
    name: "Empowerment",
    color: "#e74c3c",
    shortText: "Empowerment",
    fullText:
      "We empower businesses by providing intelligent, AI-driven tools to make informed decisions, optimize performance, and achieve their sustainability targets.",
  },
  {
    name: "Excellence",
    color: "#8e44ad",
    shortText: "Excellence",
    fullText:
      "We commit to excellence in innovation, ensuring our solutions set new standards in sustainability and intelligent building operations.",
  },
  {
    name: "Innovation",
    color: "#3498db",
    shortText: "Innovation",
    fullText:
      "We relentlessly pursue cutting-edge technology, pushing the boundaries of IoT, AI, and data analytics to revolutionize building performance and sustainability.",
  },
  {
    name: "Collaboration",
    color: "#f39c12",
    shortText: "Collaboration",
    fullText:
      "We collaborate with businesses and communities to empower them with the tools and insights needed for sustainable growth and energy efficiency.",
  },
  {
    name: "Sustainability",
    color: "#27ae60",
    shortText: "Sustainability",
    fullText:
      "We are dedicated to building a carbon-neutral future through intelligent energy management and sustainable practices that help businesses reduce their environmental impact.",
  },
];

function OurCoreValues() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [initialHover, setInitialHover] = useState(true); // State to trigger animation on page load

  useEffect(() => {
    // After 2 seconds, remove the initial hover effect
    const timer = setTimeout(() => setInitialHover(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleClickOpen = (value) => {
    setSelectedValue(value);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedValue(null);
  };

  const radius = 150; // Radius for circle arrangement
  const circleSize = 140; // Size of each circle
  const centerX = 250; // Center of the container (X-axis)
  const centerY = 250; // Center of the container (Y-axis)

  return (
    <>
      <MKBox
        sx={{
          position: "relative",
          width: "100%",
          height: "70vh", // Adjust height to allow more space for content
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <MKBox
          sx={{
            position: "relative",
            width: "500px",
            height: "500px",
            margin: "0 auto", // Center the circle arrangement
          }}
        >
          {/* Logo in the center */}
          <MKBox
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              position: "absolute",
              width: "180px", // Adjust logo size
              height: "180px",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 2, // Make sure logo is above other elements
            }}
          />

          {values.map((value, index) => {
            const angle = (index * (360 / values.length) - 90) * (Math.PI / 180); // Adjust angle for each circle
            const x = centerX + radius * Math.cos(angle) - circleSize / 2; // X position
            const y = centerY + radius * Math.sin(angle) - circleSize / 2; // Y position

            return (
              <MKBox
                key={index}
                sx={{
                  position: "absolute",
                  top: `${y}px`,
                  left: `${x}px`,
                  width: `${circleSize}px`,
                  height: `${circleSize}px`,
                  backgroundColor: value.color,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.3)",
                  transition: "transform 0.3s ease",
                  zIndex: 1, // Ensure circles are below the logo
                  transform: initialHover && index === 0 ? "scale(1.2)" : "scale(1)", // Initial hover effect for first circle
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
                onClick={() => handleClickOpen(value)}
              >
                <MKTypography
                  variant="subtitle1"
                  sx={{
                    color: "#fff",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: "0 10px",
                  }}
                >
                  {value.shortText}
                </MKTypography>
              </MKBox>
            );
          })}
        </MKBox>
      </MKBox>

      {/* Dialog Box for showing full text */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Rounded corners
            boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)", // Shadow effect
            padding: 4,
          },
        }}
        maxWidth="sm"
      >
        <DialogTitle>
          <MKTypography variant="h5" fontWeight="bold">
            {selectedValue?.shortText}
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKTypography variant="body2" color="textSecondary">
            {selectedValue?.fullText}
          </MKTypography>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default OurCoreValues;
