import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox"; // Adjust the import path according to your project structure
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import emailjs from 'emailjs-com'; // Import EmailJS
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    email: '',
    message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      email: formData.email,
      message: formData.message
    };

    emailjs.send('service_m7kd5pf', 'template_8btqyep', templateParams, '1bmxxZrEdC65PCJD9')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        // Reset form data after successful submission
        setFormData({
          name: '',
          subject: '',
          email: '',
          message: ''
        });
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 5000);
      }, (err) => {
        console.log('Failed to send email. Error: ', err);
      });
  };

  return (
    <MKBox
      component="section"
      sx={{
        minHeight: "75vh",
        width: "100%",
        backgroundColor: "white", // Set to your desired dark color
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
        py: 4, // Add padding to the top and bottom
      }}
    >
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography
               variant="h3"
               textAlign="left"
               mt={0}
               mb={1}
               sx={({ breakpoints, typography: { size } }) => ({
                   color: "#59535c",
                   [breakpoints.down("md")]: {
                       fontSize: size["3xl"],
                   },
                   [breakpoints.down("sm")]: {
                       fontSize: size["2xl"],
                   },
               })}>
              Find Us Here
            </MKTypography>
            <iframe 
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15935.77776582993!2d101.6380689!3d3.1094019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4b21fec6c627%3A0xd6bfcff75b366db5!2sSquareCloud%20Malaysia!5e0!3m2!1sen!2smy!4v1723619866998!5m2!1sen!2smy" 
            width="100%"
              height="300"
              style={{ border: 0, marginTop: "16px" }}
              allowFullScreen=""
              loading="lazy"
              title="Google Maps"
            >
            </iframe>
          </Grid>
          <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
            <MKTypography variant="body2"
              color="#59535c"
              mb={0}
              sx={({ breakpoints }) => ({
                fontSize: "1.2rem",
                lineHeight: "1.5",
                textAlign: "left",
                mt: 0,
                [breakpoints.down("md")]: {
                  fontSize: "1rem",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              })} >
              <EmailIcon />
              &nbsp;sales.squarecloud@gmail.com
              </MKTypography><br />
              <MKTypography variant="body2"
              color="#59535c"
              mb={0}
              sx={({ breakpoints }) => ({
                fontSize: "1.2rem",
                lineHeight: "1.5",
                textAlign: "left",
                mt: 0,
                [breakpoints.down("md")]: {
                  fontSize: "1rem",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              })} >
              <PhoneIcon />
              &nbsp;0374972558
              </MKTypography>
            <MKTypography variant="body2"
              color="#59535c"
              mb={0}
              sx={({ breakpoints }) => ({
                fontSize: "1.2rem",
                lineHeight: "1.5",
                textAlign: "left",
                mt: 2,
                [breakpoints.down("md")]: {
                  fontSize: "1rem",
                },
                [breakpoints.down("sm")]: {
                  fontSize: "0.875rem",
                },
              })} >
                <LocationOnIcon />
                &nbsp;D-61-3A Level 3A Jaya One,
              <br />&nbsp;&nbsp;&nbsp;&nbsp;
              No. 72A Jalan Prof Diraja Ungku Aziz
              <br />&nbsp;&nbsp;&nbsp;&nbsp;
              46200 Petaling Jaya Selangor
            </MKTypography>
          </Grid>
        </Grid>
        <Divider
          style={{
            margin: '30px 0px',
            width: '100%',
            height: '3px',
            backgroundColor: '#43abc1',
          }}
        />
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography
            variant="h3"
            textAlign="left"
            mt={0}
            mb={1}
            sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                },
                [breakpoints.down("sm")]: {
                    fontSize: size["2xl"],
                },
            })}>Contact Us Now</MKTypography>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
                required
              />
              <TextField
                fullWidth
                label="Message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
                required
              />
              <MKButton type="submit"
              variant="contained"
              sx={{
                backgroundColor: '#59535c',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#59535c',
                },
                textTransform: 'none',
              }}>
                SUBMIT
              </MKButton>
              {alertVisible && <Alert severity="success">There you go! Your email is on its way to SquareCloud</Alert>}
            </form>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactForm;
